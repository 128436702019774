<template>
  <div>
    <vx-card>
      <div v-if="philosophyData">
        <div class="header-table">
          <div class="w-full mb-8">
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/4">
                <div class="card-title">
                  <h2>{{ philosophyData.title }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fullwidth-banner">
          <img :src="philosophyData.bannerPhoto" class="responsive" />
        </div>
        <p
          class="w-full mt-5 mb-5 about-us"
          v-html="philosophyData.description"
        ></p>
        <p class="h4 mt-5">
          <a
            :href="formattedUrl(philosophyData.websiteLink)"
            target="_blank"
            class="underline"
            >website</a
          >
        </p>
      </div>
      <div v-else>
        <p>No Philosophy</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formattedUrl } from "../../../../helpers/general";

export default {
  data() {
    return {
      philosophyData: "",
    };
  },

  methods: {
    ...mapActions("philosophies", [
      "getPhilosophiesDetailsDirector",
    ]),
    formattedUrl,
    getPhilosophyDetails(id) {
      this.$vs.loading();
      this.getPhilosophiesDetailsDirector(id).then(res => {
        this.philosophyData = res.data.data;
        this.$vs.loading.close();
      });
    }
  },
  async created() {
    this.getPhilosophyDetails(this.centerAdmin.learningCenterId);
  },
  computed: {
    centerAdmin() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>
